<template>
  <div class="impressum">
    <div class="grid-container">
      <h2>Impressum & Disclaimer</h2>
      <p>
        Jan Rödger <br />
        Weichselstr. 5 <br />
        10247 Berlin <br />
        Telefon: +4917664670771<br />
        E-Mail: mail@jan-roedger.de <br />
      </p>

      <p>
        Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz: DE293088709<br />
        <br />
        Berufshaftpflichtversicherung: <br />
        exali GmbH<br />
        Franz-Kobinger-Str. 9 <br />
        86157 Augsburg<br />
        Telefon: +49 (0) 821 80 99 46-0 <br />
        Telefax: +49 (0) 821 80 99 46-29 <br />
        E-Mail: info@exali.de <br />
        Internet: www.exali.de <br />
        Geltungsbereich: Weltweit <br />
      </p>
      <h4>Disclaimer – rechtliche Hinweise</h4>
      <h5>§ 1 Warnhinweis zu Inhalten</h5>
      <p>
        Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt. Der
        Anbieter dieser Webseite übernimmt jedoch keine Gewähr für die Richtigkeit und Aktualität der bereitgestellten
        kostenlosen und frei zugänglichen journalistischen Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge
        geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder. Allein durch den
        Aufruf der kostenlosen und frei zugänglichen Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und
        dem Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des Anbieters.
      </p>
      <h5>§ 2 Externe Links</h5>
      <p>
        Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen der
        Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die
        fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine
        Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und
        auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die
        hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links ist
        für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen
        werden jedoch derartige externe Links unverzüglich gelöscht.
      </p>
      <h5>§ 3 Urheber- und Leistungsschutzrechte</h5>
      <p>
        Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht.
        Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen
        schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für
        Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in
        Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche
        gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist
        nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten
        und nicht kommerziellen Gebrauch ist erlaubt. Die Darstellung dieser Website in fremden Frames ist nur mit
        schriftlicher Erlaubnis zulässig.
      </p>
      <h5>§ 4 Besondere Nutzungsbedingungen</h5>
      <p>
        Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen,
        wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall
        die besonderen Nutzungsbedingungen.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Impressum',
};
</script>

<style scoped lang="scss"></style>
